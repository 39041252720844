import React,{useEffect,useState} from "react";
import $ from 'jquery'
import { LazyLoadImage } from 'react-lazy-load-image-component';

//let image = "https://estacas.dev.m22.mx/sites/all/themes/tickets/img/Logo.svg";
const isBrowser = typeof window !== "undefined"

const  CardComplementa = ({data,id}) => {
    
   console.log(data)
   const [datos, setData] = useState()
   var precio = data[id].precio
   precio= precio.replace('$','')
   var precioInt = parseInt(precio)
   /* setTimeout(() => {
        $("#comprarComplementa"+id).unbind('click').click(function(){
            console.log("clic")
             localStorage.setItem('dataTituloModal',data[id].título)
             localStorage.setItem('dataImagenModal',data[id].imagen)
         })
    }, 2000);*/

    const putData =(id)=>{
        console.log('clic '+id);
        var modal = document.getElementById("myModal2");
        localStorage.setItem('dataTituloModal',data[id].título)
             localStorage.setItem('dataImagenModal',data[id].imagen)
             $('#menuNav').css('z-index','1')
         setTimeout(() => {
            modal.style.display = "block";
            $('#tituloModal').text(data[id].título);
            $('#tituloModal2').text(data[id].título);

            $('#srcModal').attr('src', data[id].imagen);
            $('#srcModal2').attr('src', data[id].imagen);

            $('#precioModal').text( data[id].precio.replace('.00',''));
            $('#precioModal2').text( data[id].precio.replace('.00',''));

            $('#letraChicaModal').text( data[id].letrachica);
            $('#idModal').text( data[id].idproducto);


         }, 500);
    }
   
    setTimeout(() => {
    console.log(id)
        var modal = document.getElementById("myModal2");
       /* $("#comprarComplementa"+id).unbind('click').click(function()
       {
         console.log('clic')
        $('#menuNav').css('z-index','1')
         
         modal.style.display = "block";

        /* if ($("#author_bio_wrap_toggle").text() == "Expand Author Details")
           {			
             $("#author_bio_wrap_toggle").html("Hide Author Details")
           }
         else 
           {		
             $("#author_bio_wrap_toggle").text("Expand Author Details")
           }
         
       });*/


       $("#close2").click(function()
       {
        modal.style.display = "none";

       });
       if (isBrowser) { 
       window.onclick = function(event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      }
   }
     }, 2000);


  return (
      <div className={'d-flex justify-content-center mx-lg-3 mx-sm-0 mx-md-1'}>
          <div class="cardComplementa" style={{marginBottom:'24px'}}>
              <div>
  <LazyLoadImage src={data[id].imagen} className={'imgComplementa'} alt="Avatar" style={{width:'100%',height: '375px'}} />
  </div>
 <div className={'containerComplementa'}>
     <div className="row justify-content-between">
 <h6 style={{width:'125px'}} id="productos" data-componente="card complementa" className={'h2Complementa col-4'}>{data[id].título}</h6>
 {/*<div className="col-3"></div> */}
    <h4 style={{alignSelf: 'end'}} className={'precioComplementa col-4'}>{'$'+precioInt}</h4> 
    </div>
    <div style={{display: 'flex',justifyContent: 'center'}}>
 <a  id={"comprarComplementa"+id} onClick={()=>{putData(id)}} class="boton-azul mb-2">Comprar</a>
 </div>
 </div>
 
 
</div>
      </div>
      
     
    
  );
};

export default CardComplementa;
